import React, { useState } from "react";
import mountain from "../../Images/mountain3.jpg";
import { Link } from "react-router-dom";
import FooterWebsite from "../../Components/FooterWebsite";
import HeaderMenu from "../../Components/HeaderMenu";
import heroLogo from "../../Images/Color logo with background.svg";
import Overall_week_icon from "../../Images/Overall_week_icon.svg";
import Overall_week_icon_green from "../../Images/Overall_week_icon_green.svg";
import { HiChartBar, HiCalendar, HiBell } from "react-icons/hi";
import { LinkIcon } from "@heroicons/react/24/outline";
import { CheckIcon } from "@heroicons/react/20/solid";
import { RadioGroup } from "@headlessui/react";
import ReactPlayer from "react-player";

const includedFeatures = [
  "Weekly Self-Assessment of Mental Health and the Health Circles",
  "Create your Mental Health Connections",
  "Add Mental Health Connections to your calendar, set reminders, and invite others to join your connections",
  "Track your progress",
];

const frequencies = [
  { value: "monthly", label: "Monthly", priceSuffix: "/month" },
  { value: "annually", label: "Annually", priceSuffix: "/year" },
];

const features = [
  {
    name: "Create your Mental Health Connections",
    icon: LinkIcon,
  },
  {
    name: "Add Mental Health Connections to your calendar, set reminders, and invite others to join your connections",
    icon: HiCalendar,
  },
  {
    name: "Track your progress",
    icon: HiChartBar,
  },
];

const tiers = [
  {
    name: "month",
    label: "Monthly",
    id: "tier-monthly",
    href: "#",
    price: "$3",
    description:
      "Most flexible, for those who want to test out the waters or are new to tracking there Mental Health.",
    features: [
      "Weekly Self-Assessment of Mental Health and the Health Circles",
      "Create your Mental Health Connections",
      "Add Mental Health Connections to your calendar, set reminders, and invite others to join your connections",
      "Track your progress",
    ],
    featured: false,
    cta: "Buy plan",
  },
  {
    name: "annually",
    label: "Annually",
    id: "tier-annual",
    href: "#",
    price: "$20",
    description:
      "Most popular, for those who want to fully immerse themselves with Mental Health Connections features.",
    features: [
      "Weekly Self-Assessment of Mental Health and the Health Circles",
      "Create your Mental Health Connections",
      "Add Mental Health Connections to your calendar, set reminders, and invite others to join your connections",
      "Track your progress",
    ],
    featured: true,
    cta: "Contact sales",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function LandingPage() {
  const [frequency, setFrequency] = useState(frequencies[0]);

  return (
    <div className='bg-background w-full '>
      {/* Header */}
      <HeaderMenu />
      <main className='isolate bg-background'>
        {/* Hero section */}
        <div className='relative pt-28 pb-10'>
          <img
            src={mountain}
            alt='Mountain'
            className='-z-10 absolute w-full h-full object-cover top-0 left-0'
          />
          <div className='sm:py-8 md:py-8 h-2/3'>
            <div className='mx-auto  px-6 lg:px-8'>
              <div className='mx-auto max-w-3xl text-center'>
                <img
                  src={heroLogo}
                  alt='Mental Health Connections Logo'
                  className='h-auto w-hero lg:w-hero mx-auto'
                />

                <div className='mt-10 flex items-center justify-center gap-x-6'>
                  <Link
                    id='getStarted'
                    to='/ComingSoonPage'
                    state={{ plan: "" }}
                    className='rounded-md bg-primary px-3.5 py-2.5 sm:text-sm md:text-lg font-semibold text-light-text shadow-sm hover:bg-primary-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-light'
                  >
                    Get started
                  </Link>
                  <Link
                    id='learnMore'
                    to='/ProductPage'
                    className='sm:text-sm md:text-lg font-semibold leading-6 text-gray-50 hover:text-primary'
                  >
                    Learn more <span aria-hidden='true'>→</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div
            className='absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]'
            aria-hidden='true'
          >
            <div
              className='relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]'
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
        </div>
        <div className='mt-10 pt-4 flow-root sm:mt-24'>
          <div className=' py-24 sm:py-24'>
            <div className='mx-auto max-w-7xl px-6 lg:px-8 flex justify-center'>
              <div className='mx-auto  max-w-2xl lg:mx-0 '>
                <h2 className='text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl text-center'>
                  A video is worth a thousand words
                </h2>
                <p className='mt-6 text-lg text-center leading-8 text-gray-600'>
                  Get started tracking your mental health with this short video
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='flex justify-center '>
          <div className='-m-2 w-5/6 md:w-3/4 mx-auto rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4 h-64 sm:h-96 xl:h-screen'>
            <ReactPlayer
              url='https://vimeo.com/929354403?share=copy'
              controls
              width='100%'
              height='100%'
              className=''
            />
          </div>
        </div>

        {/* Feature section */}
        <div className='relative mx-auto mt-28 max-w-7xl px-6  sm:mt-32 md:mt-40 lg:px-8'>
          <div className='absolute inset-0 -z-10 '>
            <div
              className='absolute left-0 top-0 -z-10 w-full transform-gpu overflow-hidden blur-3xl sm:top-0'
              aria-hidden='true'
            >
              <div
                className='relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-primary-light to-primary opacity-30 sm:left-4 sm:w-[72.1875rem]'
                style={{
                  clipPath:
                    "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                }}
              />
            </div>
            <div
              className='absolute left-0 bottom-0 -z-10 transform-gpu overflow-hidden blur-3xl sm:bottom-0'
              aria-hidden='true'
            >
              <div
                className='aspect-[1097/845] w-full md:w-[40rem] lg:w-[60.5625rem] xl:w-[70rem] bg-gradient-to-r from-primary-light to-primary opacity-25'
                style={{
                  clipPath:
                    "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                }}
              />
            </div>
          </div>
          <div className='mx-auto max-w-2xl lg:text-center'>
            <p className='mt-2 text-3xl font-bold tracking-tight text-dark-text sm:text-4xl'>
              A great tool to take your mental health into your own hands
            </p>
          </div>
          <div className='mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl'>
            <dl className='grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16'>
              <div
                key='weekly self-assessement of mental health'
                className='relative pl-16'
              >
                <dt className='text-lg font-semibold leading-7 text-dark-text'>
                  <img
                    src={Overall_week_icon}
                    alt='Overall week icon'
                    className='absolute left-0 top-0 h-10 w-10 p-1 text-white rounded-lg bg-primary'
                  />
                  Weekly Self-Assessment of Mental Health and the Health Circles
                </dt>
              </div>

              {features.map((feature) => (
                <div key={feature.name} className='relative pl-16'>
                  <dt className='text-lg font-semibold leading-7 text-dark-text'>
                    <div className='absolute left-0 top-0 flex text-lg h-10 w-10 items-center justify-center rounded-lg bg-primary'>
                      <feature.icon
                        className='h-6 w-6 text-white'
                        aria-hidden='true'
                      />
                    </div>
                    {feature.name}
                  </dt>
                </div>
              ))}
            </dl>
          </div>
        </div>

        {/* Pricing section */}
        <div id='pricing' className='py-12 sm:pt-20 '>
          <div className='mx-auto '>
            <div className='w-11/12  mx-auto'>
              {/* <div className='bg-light-text border-2 border-dark-text rounded-lg m-4 p-6 sm:p-8 shadow-lg '>
                <div className='mx-auto max-w-4xl text-center'>
                  <p className='mt-2 text-4xl font-bold tracking-tight text-dark-text sm:text-5xl'>
                    Pricing Plans for Everyone
                  </p>
                </div>
                <p className='mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-dark-text'>
                  Members get access to all the features of the Mental Health
                  Connections Self-Prescription activity, including the ability
                  to:
                </p>
                <div className='text-center mt-4'>
                  <ul className='list-disc text-lg p-2 sm:p-8 leading-8 text-dark-text mx-auto inline-block text-left'>
                    <li>create and save Mental Health Connections</li>
                    <li>
                      add your Mental Health Connections to your calendar and
                      share with others
                    </li>
                    <li>
                      set reminders in your calendar to revisit the Mental
                      Health Connections Self-Prescription activity
                    </li>
                    <li>
                      complete self-assessments of your Mental Health and the
                      Health Circles
                    </li>
                    <li>
                      track and view your progress in graph formats over time
                    </li>
                  </ul>
                </div>
              </div> */}
              <div className=' py-24 sm:py-32'>
                <div className='mx-auto max-w-7xl px-6 lg:px-8'>
                  <div className='mx-auto max-w-2xl sm:text-center'>
                    <h2 className='text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
                      Pricing Plans for Everyone
                    </h2>
                    <p className='mt-6 text-lg leading-8 text-gray-600'>
                      Members get access to all the features of the Mental
                      Health Connections Self-Prescription activity.
                    </p>
                  </div>
                  <div className='mt-16 flex justify-center'>
                    <RadioGroup
                      value={frequency}
                      onChange={setFrequency}
                      className='grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-md font-semibold leading-5 ring-1 ring-inset ring-dark-text'
                    >
                      <RadioGroup.Label className='sr-only'>
                        Payment frequency
                      </RadioGroup.Label>
                      {frequencies.map((option) => (
                        <RadioGroup.Option
                          key={option.value}
                          value={option}
                          className={({ checked }) =>
                            classNames(
                              checked
                                ? "bg-primary text-white"
                                : "text-dark-text",
                              "cursor-pointer rounded-full px-2.5 py-1"
                            )
                          }
                        >
                          <span>{option.label}</span>
                        </RadioGroup.Option>
                      ))}
                    </RadioGroup>
                  </div>
                  {tiers.map((tier, tierIdx) => {
                    if (tier.label === frequency.label) {
                      return (
                        <div
                          key={tier.name}
                          className='mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-dark-text sm:mt-20 lg:mx-0 lg:flex lg:max-w-none'
                        >
                          <div className='p-8 sm:p-10 lg:flex-auto'>
                            <h3 className='text-2xl font-bold tracking-tight text-gray-900'>
                              {tier.label}
                            </h3>
                            <p className='mt-6 text-base leading-7 text-dark-text'>
                              {tier.description}
                            </p>
                            <div className='mt-10 flex items-center gap-x-4'>
                              <h4 className='flex-none text-sm font-semibold leading-6 text-primary'>
                                What’s included
                              </h4>
                              <div className='h-px flex-auto bg-dark-text' />
                            </div>
                            <ul
                              s
                              className='mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6'
                            >
                              {includedFeatures.map((feature) => (
                                <li key={feature} className='flex gap-x-3'>
                                  <CheckIcon
                                    className='h-6 w-5 flex-none text-primary'
                                    aria-hidden='true'
                                  />
                                  {feature}
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div className='-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0'>
                            <div className='rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16'>
                              <div className='mx-auto max-w-xs px-8'>
                                <p className='text-base font-semibold text-gray-600'>
                                  Starting at
                                </p>
                                <p className='mt-6 flex items-baseline justify-center gap-x-2'>
                                  <span className='text-5xl font-bold tracking-tight text-gray-900'>
                                    {tier.price}
                                  </span>
                                  <span className='text-sm font-semibold leading-6 tracking-wide text-gray-600'>
                                    {frequencies[tierIdx].priceSuffix}
                                  </span>
                                </p>
                                <a
                                  href='/ComingSoonPage'
                                  className='mt-10 block w-full rounded-md bg-primary px-3 py-2 text-center text-sm font-semibold text-white hover:text-dark-text shadow-sm hover:bg-primary-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-light'
                                >
                                  Get access
                                </a>
                                <p className='mt-6 text-xs leading-5 text-gray-600'>
                                  Invoices and receipts available for easy
                                  company reimbursement
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
            <div className='grid grid-cols-5 gap-3 from-slate-500 to-light-text p-4 h-24'>
              <div>
                <img
                  src={Overall_week_icon_green}
                  alt='Overall week icon green'
                  className='h-1/2 sm:h-1/4 mx-auto w-auto text-primary'
                />
              </div>
              <div>
                <LinkIcon className='h-1/2 sm:h-1/4 mx-auto w-auto text-primary' />
              </div>
              <div>
                <HiCalendar className='h-1/2 sm:h-1/4 mx-auto w-auto text-primary' />
              </div>
              <div>
                <HiBell className='h-1/2 sm:h-1/4 mx-auto w-auto text-primary' />
              </div>

              <div>
                <HiChartBar className='h-1/2 sm:h-1/4 mx-auto w-auto text-primary' />
              </div>
            </div>

            <div className='isolate px-6 lg:px-12 mx-auto mt-8 sm:mt-12 grid max-w-md grid-cols-1 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3'></div>
          </div>
          <div className='relative mx-auto max-w-full divide-y divide-dark-text '>
            <div className='absolute inset-0 -z-10 '>
              <div
                className='absolute left-0 top-0 -z-10 max-w-full transform-gpu overflow-hidden blur-3xl sm:top-0'
                aria-hidden='true'
              >
                <div
                  className='relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-primary-light to-primary opacity-30 sm:left-4 sm:w-[72.1875rem]'
                  style={{
                    clipPath:
                      "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                  }}
                />
              </div>
              <div
                className='absolute left-0 bottom-0 -z-10 transform-gpu overflow-hidden blur-3xl sm:bottom-0'
                aria-hidden='true'
              >
                <div
                  className='aspect-[1097/845] w-full md:w-[40rem] lg:w-[60.5625rem] xl:w-[70rem] bg-gradient-to-r from-primary-light to-primary opacity-25'
                  style={{
                    clipPath:
                      "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                  }}
                />
              </div>
            </div>
          </div>
          <div className='mt-12 mx-auto max-w-4xl text-center'>
            <p className='mt-2 text-3xl font-bold tracking-tight text-dark-text sm:text-4xl'>
              We think your mental health is worth it
            </p>
          </div>

          <div className=' py-16 sm:py-24 lg:py-32'>
            <div className='mx-auto grid max-w-7xl grid-cols-1 gap-10 px-6 lg:grid-cols-12 lg:gap-8 lg:px-8'>
              <div className='max-w-xl text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:col-span-7'>
                <h2 className='inline sm:block lg:inline xl:block'>
                  Want to share this with your friends?
                </h2>{" "}
                <p className='inline sm:block lg:inline xl:block'>
                  Send then an invite
                </p>
              </div>
              <form className='w-full max-w-md lg:col-span-5 lg:pt-2'>
                <div className='flex gap-x-4'>
                  <label htmlFor='email-address' className='sr-only'>
                    Email address
                  </label>
                  <input
                    id='email-address'
                    name='email'
                    type='email'
                    autoComplete='email'
                    required
                    className='min-w-0 flex-auto rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                    placeholder='Enter email'
                  />
                  <button
                    type='submit'
                    className='flex-none rounded-md bg-primary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary'
                  >
                    Send invite
                  </button>
                </div>
                <p className='mt-4 text-sm leading-6 text-gray-900'>
                  We care about your data. Read our{" "}
                  <a
                    href='/PrivacyPage'
                    className='font-semibold text-primary hover:underline'
                  >
                    privacy&nbsp;policy
                  </a>
                  .
                </p>
              </form>
            </div>
          </div>
        </div>
      </main>

      {/* Footer */}
      <FooterWebsite />
    </div>
  );
}
